@import '../../../../node_modules/react-tippy/dist/tippy.css';
@import '../content/fonts.postcss';
@import '../../variables.postcss';

/* TODO remove react-tippy? No need for 2 tippy packages. */

.tippy-tooltip {
  background-color: $darkGrey;
  padding: 0.1rem 0.5rem;
}

.tippy-tooltip-content a {
  @mixin gordita-medium;
  color: white;
  font-size: 0.75rem;
}