@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Rating_wrapper_yelp {
  align-items: center;
  display: flex;

  .Rating_yelp {
    height: 25px;
  }

  .Rating_yelp_logo {
    height: 40px;
    margin-left: 0.5rem;
  }

  &+.Rating_explanation {
    @mixin gordita-regular;
    font-size: 0.75rem;
    font-weight: normal;
    margin-left: 0;
  }

  @mixin below $medium {
    justify-content: center;
  }
}